@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.ant-layout .ant-menu .ant-layout-sider .ant-card-body {
  font-family: "Poppins", sans-serif !important;
}

p,
span,
label,
a,
ul,
li,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.radio-green .ant-radio-checked .ant-radio-inner {
  /* border-color: #52c41a !important; */
  background-color: #52c41a !important;
}
.radio-red .ant-radio-checked .ant-radio-inner {
  /* border-color: #ff4d4f !important; */
  background-color: #ff4d4f !important;
}
.radio-yellow .ant-radio-checked .ant-radio-inner {
  /* border-color: #ff4d4f !important; */
  background-color: #e4e008 !important;
}

.radio-blue .ant-radio-checked .ant-radio-inner {
  /* border-color: #ff4d4f !important; */
  background-color: #0c08e4 !important;
}

.student-dashboard-wrapper {
  padding: 24px;
}

.student-name {
  font-weight: 600;
  color: #3f51b5;
}

.custom-card {
  color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  /* transition: transform 0.2s ease; */
}

/* .custom-card:hover {
  transform: translateY(-5px);
} */

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-left {
  text-align: left;
}

.card-value {
  font-size: 24px;
  font-weight: bold;
}

.card-title {
  font-size: 14px;
  opacity: 0.9;
}

.card-icon {
  font-size: 32px;
  opacity: 0.85;
}
